<template>
    <div v-loading.fullscreen.lock="loading">
        <cop-freight-search-search-bar-component
            @search="get_freight_search"
        ></cop-freight-search-search-bar-component>
        <common-table-component
            button_name="查看"
            :col_arr="fcol_arr"
            :table_data="table_data"
            table_size="small"
            table_height="680px"
            @watch_row="watch_row"
        ></common-table-component>
        <cop-freight-search-edit-component
            :dialogFormVisible="dialogFormVisible"
            :row_data="row_data"
            :row_extra_charge="row_extra_charge"
            @exitDialog="exitDialog"
        ></cop-freight-search-edit-component>
    </div>
</template>

<script>
import CopFreightSearchSearchBarComponent from '@/components/cop/cop/CopFreightSearchSearchBarComponent'
import CopFreightSearchEditComponent from '@/components/cop/cop/CopFreightSearchEditComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'

import { cop_freight_search_request,cop_extra_charge_request } from '@/network/cop/cop.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'',
    data(){
        return {
            loading: false,
            cond: {
                porCityId: '',
                fndCityId: '',
                startDate: '',
                endDate: ''
            },
            table_data: [],
            fcol_arr: [
                {
                    prop: 'porCity',
                    label: '起运地',
                    minWidth: '150px',
                    html: (v) => {
                        const start = v.scheduleData[0]['etd']['etd'] ? v.scheduleData[0]['etd']['etd'].split(' ')[0] : ''
                        let h = ''
                        h += '<div style="font-weight: bold;font-size: 15px;">'+v.porCity['cityFullNameCn']+'</div>'
                        h += '<div>'+v.porCity['cityFullNameEn']+'</div>'
                        h += '<div style="color: #009100;">Start：'+start+'</div>'
                        return h
                    }
                },
                {
                    prop: 'scheduleData',
                    label: '中转港',
                    minWidth: '150px',
                    html: (v) => {
                        let h = ''
                        if(v.scheduleData.length !== 1) {
                            const etd = v.scheduleData[v.scheduleData.length-1]['etd']['etd'] ? v.scheduleData[v.scheduleData.length-1]['etd']['etd'].split(' ')[0] : ''
                            const eta = v.scheduleData[0]['eta']['eta'] ? v.scheduleData[0]['eta']['eta'].split(' ')[0] : ''
                            h += '<div style="color: #CE0000;">ETA：'+eta+'</div>'
                            h += '<div style="font-weight: bold;font-size: 15px;">'+v.scheduleData[0]['eta']['portFullNameCn']+'</div>'
                            h += '<div>'+v.scheduleData[0]['eta']['portFullNameEn']+'</div>'
                            h += '<div style="color: #009100;">ETD：'+etd+'<div>'
                        } else {
                            h += '-'
                        }
                        
                        return h
                    }
                },
                {
                    prop: 'fndCity',
                    label: '目的地',
                    minWidth: '150px',
                    html: (v) => {
                        const end = v.scheduleData[v.scheduleData.length-1]['eta']['eta'] ? v.scheduleData[v.scheduleData.length-1]['eta']['eta'].split(' ')[0] : ''
                        let h = ''
                        h += '<div style="font-weight: bold;font-size: 15px;">'+v.fndCity['cityFullNameCn']+'</div>'
                        h += '<div>'+v.fndCity['cityFullNameEn']+'</div>'
                        h += '<div style="color: #CE0000;">End：'+end+'</div>'
                        return h
                    }
                },
                {
                    prop: 'estimatedTransitTimeInDays',
                    label: '预交货期',
                    minWidth: '90px',
                    editdata: (v) => {
                        return v['estimatedTransitTimeInDays']+'天'
                    }
                },
                {
                    prop: 'vesselName',
                    label: '船信息',
                    minWidth: '120px',
                    html: (v) => {
                        let h = ''
                        h += '<div>'+v.vesselName+'</div>'
                        h += '<div>'+v.direction+'</div>'
                        h += '<div>'+v.haulageType+'</div>'
                        return h
                    }
                },{
                    prop: 'routeProductPricingList',
                    label: '运价',
                    minWidth: '180px',
                    html: (v) => {
                        let h = ''
                        h += '<div style="font-weight: bold;font-size: 15px;">20GP：'+this.filter_pice(v.routeProductPricingList,'20GP')+'</span></div>'
                        h += '<div style="font-weight: bold;font-size: 15px;">40GP：'+this.filter_pice(v.routeProductPricingList,'40GP')+'</span></div>'
                        h += '<div style="font-weight: bold;font-size: 15px;">40HQ：'+this.filter_pice(v.routeProductPricingList,'40HQ')+'</span></div>'
                        return h
                    }
                },{
                    prop: 'effectiveStartDate',
                    label: '有效期',
                    minWidth: '120px',
                    html: (v) => {
                        let h = ''
                        h += '<div>'+v.effectiveStartDate+'</div>'
                        h += '<div>至</div>'
                        h += '<div>'+v.effectiveEndDate+'</div>'
                        return h
                    }
                },{
                    prop: 'inventory',
                    label: '库存',
                    minWidth: '80px',
                    html: (v) => {
                        if(v.inventory) {
                            return '<div style="font-weight: bold;font-size: 15px;color: #009100;">'+v.inventory+'</div>'
                        } else {
                            return '<div style="font-weight: bold;font-size: 15px;color: #CE0000;">0</div>'
                        }
                    }
                },
            ],
            dialogFormVisible: false,
            row_data: {},
            row_extra_charge: {}
        }
    },
    computed:{},
    methods:{
        get_freight_search(param) {
            this.loading = true
            cop_freight_search_request(param)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        watch_row(row) {
            this.row_data = row
            this.loading = true
            cop_extra_charge_request({ porductId: row.productId })
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.row_extra_charge = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
            this.dialogFormVisible = true
        },
        filter_pice(row,pice) {
            const pice_arr = row.filter(v=> v.cntrType===pice)
            return pice_arr.length === 0 ? '-' : '$ '+thousandBitSeparator(fomatFloat(pice_arr[0]['price']))
        },
        exitDialog() {
            this.dialogFormVisible = false
        }
    },
    filters:{},
    props:{},
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CopFreightSearchSearchBarComponent,
        CopFreightSearchEditComponent,
        CommonTableComponent
    },
    watch:{}
}
</script>

<style>
</style>