<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="small"
        >
            <el-form-item label="出发地" prop="porCityId">
                <el-select
                    v-model="form.porCityId"
                    placeholder="选择出发地"
                    class="porCityIdc"
                    filterable
                    clearable
                    remote
                    :remote-method="remote_pro_city"
                >
                    <el-option
                        v-for="item in pro_citys"
                        :key="item.id"
                        :label="item.cityFullNameCn"
                        :value="item.id"
                    >
                        <span style="float: left">{{ item.cityFullNameCn }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cityFullNameEn }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="目的地" prop="fndCityId">
                <el-select
                    v-model="form.fndCityId"
                    placeholder="选择出发地"
                    class="fndCityId"
                    filterable
                    clearable
                    remote
                    :remote-method="remote_fnd_city"
                >
                    <el-option
                        v-for="item in fnd_citys"
                        :key="item.id"
                        :label="item.cityFullNameCn"
                        :value="item.id"
                    >
                        <span style="float: left">{{ item.cityFullNameCn }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cityFullNameEn }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="最早开航日期" prop="startDate">
                <el-date-picker
                    v-model="form.startDate"
                    align="right"
                    type="date"
                    placeholder="选择开航时间"
                    value-format="yyyy-MM-dd"
                    class="startDatec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="最晚开航日期" prop="endDate">
                <el-date-picker
                    v-model="form.endDate"
                    align="right"
                    type="date"
                    placeholder="选择开航时间"
                    value-format="yyyy-MM-dd"
                    class="startDatec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
import { cop_port_search_request } from '@/network/cop/cop.js'

export default {
    name: 'CopFreightSearchSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                porCityId: '',
                fndCityId: '',
                startDate: '',
                endDate: ''
            },
            pro_citys: [],
            fnd_citys: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
            this.pro_citys = []
            this.fnd_citys = []
        },
        remote_pro_city(query) {
            if (query !== '') {
                this.login_loading = true
                setTimeout(() => {
                    cop_port_search_request(query).then((s) => {
                        this.login_loading = false
                        if (s.status === 0) {
                            this.pro_citys = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.login_loading = false
                        this.$message.error(err)
                    })
                }, 1000);
            } else {
                this.pro_citys = [];
            }
        },
        remote_fnd_city(query) {
            if (query !== '') {
                this.login_loading = true
                setTimeout(() => {
                    cop_port_search_request(query).then((s) => {
                        this.login_loading = false
                        if (s.status === 0) {
                            this.fnd_citys = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.login_loading = false
                        this.$message.error(err)
                    })
                }, 1000);
            } else {
                this.fnd_citys = [];
            }
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>

</style>