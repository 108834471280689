<template>
    <div>
        <el-dialog
            title="产品详情"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="90%"
            :center="true"
            top="3vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <fieldset class="fieldsetc">
                <legend class="legendc">基础信息</legend>
                <el-descriptions
                    class="margin-top"
                    :column="6"
                    :labelStyle="{'text-align':'center'}"
                    :contentStyle="{'text-align': 'center'}"
                    direction="vertical"
                    border
                    size="small"
                >
                    <el-descriptions-item>
                        <template slot="label">
                            <span>起运地</span>
                        </template>
                        <span v-html="porCity"></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>中转港</span>
                        </template>
                        <span v-html="scheduleData"></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>目的地</span>
                        </template>
                        <span v-html="fndCity"></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>预交货期</span>
                        </template>
                        <span>{{row_data.estimatedTransitTimeInDays+'天'}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>船信息</span>
                        </template>
                        <span v-html="vesselName"></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>有效期</span>
                        </template>
                        <span v-html="effectiveStartDate"></span>
                    </el-descriptions-item>
                </el-descriptions>
            </fieldset>
            <fieldset class="fieldsetc">
                <legend class="legendc">附加费明细</legend>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <fieldset class="fieldsetc">
                            <legend class="legend_price1">20GP</legend>
                            <el-table
                                :data="row_extra_charge['20GP']"
                                border
                                highlight-current-row
                                style="width: 99%"
                                size="mini"
                                height="260"
                            >
                                <el-table-column
                                    align="center"
                                    label="费用名称"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['name'] }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="费用类型"
                                >
                                    <template slot-scope="scope">
                                        <el-tag type="success" size="small" v-if="scope.row['type'] === 'POR'">起运地</el-tag>
                                        <el-tag type="danger" size="small" v-else-if="scope.row['type'] === 'FND'">目的地</el-tag>
                                        <el-tag size="small" v-else-if="scope.row['type'] === 'OCEAN'">海运</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="单价"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['price'] | diy_price }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </fieldset>
                    </el-col>
                    <el-col :span="12">
                        <fieldset class="fieldsetc">
                            <legend class="legend_price1">40GP</legend>
                            <el-table
                                :data="row_extra_charge['40GP']"
                                border
                                highlight-current-row
                                style="width: 99%"
                                size="mini"
                                height="260"
                            >
                                <el-table-column
                                    align="center"
                                    label="费用名称"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['name'] }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="费用类型"
                                >
                                    <template slot-scope="scope">
                                        <el-tag type="success" size="small" v-if="scope.row['type'] === 'POR'">起运地</el-tag>
                                        <el-tag type="danger" size="small" v-else-if="scope.row['type'] === 'FND'">目的地</el-tag>
                                        <el-tag size="small" v-else-if="scope.row['type'] === 'OCEAN'">海运</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="单价"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['price'] | diy_price }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </fieldset>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <fieldset class="fieldsetc">
                            <legend class="legend_price1">40HQ</legend>
                            <el-table
                                :data="row_extra_charge['40HQ']"
                                border
                                highlight-current-row
                                style="width: 99%"
                                size="mini"
                                height="260"
                            >
                                <el-table-column
                                    align="center"
                                    label="费用名称"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['name'] }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="费用类型"
                                >
                                    <template slot-scope="scope">
                                        <el-tag type="success" size="small" v-if="scope.row['type'] === 'POR'">起运地</el-tag>
                                        <el-tag type="danger" size="small" v-else-if="scope.row['type'] === 'FND'">目的地</el-tag>
                                        <el-tag size="small" v-else-if="scope.row['type'] === 'OCEAN'">海运</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="单价"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['price'] | diy_price }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </fieldset>
                    </el-col>
                    <el-col :span="12">
                        <fieldset class="fieldsetc">
                            <legend class="legend_price2">单证费</legend>
                            <el-table
                                :data="row_extra_charge['BL']"
                                border
                                highlight-current-row
                                style="width: 99%"
                                size="mini"
                                height="260"
                            >
                                <el-table-column
                                    align="center"
                                    label="费用名称"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['name'] }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="费用类型"
                                >
                                    <template slot-scope="scope">
                                        <el-tag type="warning" size="small" v-if="scope.row['type'] === 'POR'">出发地单证</el-tag>
                                        <el-tag type="danger" size="small" v-if="scope.row['type'] === 'OCEAN'">海运单证</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="单价"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row['price'] | diy_price }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </fieldset>
                    </el-col>
                </el-row>
            </fieldset>
            <fieldset class="fieldsetc">
                <legend class="legendc">购买信息</legend>
                <el-table
                    :data="row_data.routeProductPricingList"
                    border
                    highlight-current-row
                    style="width: 99%"
                    size="mini"
                >
                    <el-table-column
                        align="center"
                        label="箱型"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row['cntrType'] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="单价"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row['currency']+' '+scope.row['price'] | diy_price}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        :label="'数量(剩余 '+surplus_inventory+' TEU)'"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                v-if="scope.row['cntrType']==='20GP'"
                                v-model="form.num_20GP"
                                :min="0"
                                :max="row_data.inventory-form.num_40GP*2-form.num_40HQ*2"
                                :step="1"
                                :precision="0"
                                :step-strictly="true"
                                size="mini"
                            ></el-input-number>
                            <el-input-number
                                v-else-if="scope.row['cntrType']==='40GP'"
                                v-model="form.num_40GP"
                                :min="0"
                                :max="(row_data.inventory-form.num_20GP-form.num_40HQ*2)/2"
                                :step="1"
                                :precision="0"
                                :step-strictly="true"
                                size="mini"
                            ></el-input-number>
                            <el-input-number
                                v-else-if="scope.row['cntrType']==='40HQ'"
                                v-model="form.num_40HQ"
                                :min="0"
                                :max="(row_data.inventory-form.num_20GP-form.num_40GP*2)/2"
                                :step="1"
                                :precision="0"
                                :step-strictly="true"
                                size="mini"
                            ></el-input-number>
                        </template>
                    </el-table-column>
                </el-table>
                <el-descriptions
                    class="margin-top"
                    :column="2"
                    :labelStyle="{'text-align':'center'}"
                    :contentStyle="{'text-align': 'center','font-size': '14px','font-weight': 'bolder'}"
                    direction="vertical"
                    border
                    size="small"
                >
                    <el-descriptions-item>
                        <template slot="label">
                            <span>单证</span>
                        </template>
                        <el-input-number
                            v-model="form.num_DZ"
                            :min="0"
                            :step="1"
                            :precision="0"
                            :step-strictly="true"
                            size="mini"
                        ></el-input-number>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>保价</span>
                        </template>
                        <el-radio-group v-model="form.baojia" size="medium" :fill="form.baojia===0 ? '#FF0000' : '#00DB00'">
                            <el-radio-button :label=0>否</el-radio-button>
                            <el-radio-button :label=1>是</el-radio-button>
                        </el-radio-group>
                    </el-descriptions-item>
                </el-descriptions>
            </fieldset>
            <fieldset class="fieldsetc">
                <legend class="legendc">结算信息</legend>
                <el-descriptions
                    class="margin-top"
                    :column="6"
                    :labelStyle="{'text-align':'center'}"
                    :contentStyle="{'text-align': 'center','font-size': '14px','font-weight': 'bolder'}"
                    direction="vertical"
                    border
                    size="small"
                >
                    <el-descriptions-item>
                        <template slot="label">
                            <span>海运+装港驳船费</span>
                        </template>
                        <div v-if="money1_usd">{{'USD '+money1_usd | diy_price}}</div>
                        <div v-if="money1_cny">{{'CNY '+money1_cny | diy_price}}</div>
                        <div v-if="(money1_usd===0) && (money1_cny===0)">-</div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>单证费</span>
                        </template>
                        <div v-if="money2_usd">{{'USD '+money2_usd | diy_price}}</div>
                        <div v-if="money2_cny">{{'CNY '+money2_cny | diy_price}}</div>
                        <div v-if="(money2_usd===0) && (money2_cny===0)">-</div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>起运地附加费</span>
                        </template>
                        <div v-if="money3_usd">{{'USD '+money3_usd | diy_price}}</div>
                        <div v-if="money3_cny">{{'CNY '+money3_cny | diy_price}}</div>
                        <div v-if="(money3_usd===0) && (money3_cny===0)">-</div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>海运附加费</span>
                        </template>
                        <div v-if="money4_usd">{{'USD '+money4_usd | diy_price}}</div>
                        <div v-if="money4_cny">{{'CNY '+money4_cny | diy_price}}</div>
                        <div v-if="(money4_usd===0) && (money4_cny===0)">-</div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <span>合计</span>
                        </template>
                        <span v-html="sum_money"></span>
                    </el-descriptions-item>
                </el-descriptions>
            </fieldset>
            <div slot="footer" class="dialog-footer">
                <div>
                    <el-button
                        type="success"
                        plain
                        @click="add_order"
                        >下单</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog"
                        >关闭</el-button
                    >
                </div>
            </div>
            <address-form-component
                :AddressFormVisible="AddressFormVisible"
                :order_data="order_data"
                :row_data="row_data"
                :container="form"
                @ExitAddressDialog="ExitAddressDialog"
                @details_order="details_order"
            ></address-form-component>
        </el-dialog>
    </div>
</template>

<script>
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'
import AddressFormComponent from './AddressFormComponent'

export default {
    name:'CopFreightSearchEditComponent',
    data(){
        return {
            login_loading: false,
            form: {
                num_20GP: 0,
                num_40GP: 0,
                num_40HQ: 0,
                num_DZ: 0,
                baojia: 0
            },
            table_data: [],
            AddressFormVisible: false,
            order_data: []
        }
    },
    computed:{
        porCity() {
            if(this.row_data.scheduleData) {
                const start = this.row_data.scheduleData[0]['etd']['etd'] ? this.row_data.scheduleData[0]['etd']['etd'].split(' ')[0] : ''
                let h = ''
                h += '<div style="font-weight: bold;font-size: 15px;">'+this.row_data.porCity['cityFullNameCn']+'</div>'
                h += '<div>'+this.row_data.porCity['cityFullNameEn']+'</div>'
                h += '<div style="color: #009100;">Start：'+start+'</div>'
                return h
            }
        },
        scheduleData() {
            let h = ''
            if((this.row_data.scheduleData) && (this.row_data.scheduleData.length !== 1)) {
                const etd = this.row_data.scheduleData[this.row_data.scheduleData.length-1]['etd']['etd'] ? this.row_data.scheduleData[this.row_data.scheduleData.length-1]['etd']['etd'].split(' ')[0] : ''
                const eta = this.row_data.scheduleData[0]['eta']['eta'] ? this.row_data.scheduleData[0]['eta']['eta'].split(' ')[0] : ''
                h += '<div style="color: #CE0000;">ETA：'+eta+'</div>'
                h += '<div style="font-weight: bold;font-size: 15px;">'+this.row_data.scheduleData[0]['eta']['portFullNameCn']+'</div>'
                h += '<div>'+this.row_data.scheduleData[0]['eta']['portFullNameEn']+'</div>'
                h += '<div style="color: #009100;">ETD：'+etd+'<div>'
            } else {
                h += '-'
            }
            return h
        },
        fndCity() {
            if(this.row_data.scheduleData) {
                const end = this.row_data.scheduleData[this.row_data.scheduleData.length-1]['eta']['eta'] ? this.row_data.scheduleData[this.row_data.scheduleData.length-1]['eta']['eta'].split(' ')[0] : ''
                let h = ''
                h += '<div style="font-weight: bold;font-size: 15px;">'+this.row_data.fndCity['cityFullNameCn']+'</div>'
                h += '<div>'+this.row_data.fndCity['cityFullNameEn']+'</div>'
                h += '<div style="color: #CE0000;">End：'+end+'</div>'
                return h
            }
        },
        vesselName() {
            let h = ''
            h += '<div>'+this.row_data.vesselName+'</div>'
            h += '<div>'+this.row_data.direction+'</div>'
            h += '<div>'+this.row_data.haulageType+'</div>'
            return h
        },
        effectiveStartDate() {
            let h = ''
            h += '<div>'+this.row_data.effectiveStartDate+'</div>'
            h += '<div>至</div>'
            h += '<div>'+this.row_data.effectiveEndDate+'</div>'
            return h
        },
        //剩余库存
        surplus_inventory() {
            return this.row_data.inventory-this.form.num_20GP-this.form.num_40GP*2-this.form.num_40HQ*2
        },
        //海运+装港驳船费USD
        money1_usd() {
            let m20gp_price = 0
            let m40gp_price = 0
            let m40hq_price = 0
            let bj_price = 5;
            if(this.row_data.routeProductPricingList) {
                this.row_data.routeProductPricingList.forEach(e => {
                    if((e.cntrType==='20GP') && (e.currency==='USD')) {
                        m20gp_price = e.price
                    } else if((e.cntrType==='40GP') && (e.currency==='USD')) {
                        m40gp_price = e.price
                    } else if((e.cntrType==='40HQ') && (e.currency==='USD')) {
                        m40hq_price = e.price
                    }
                })
            }
        
            m20gp_price = m20gp_price*this.form.num_20GP
            m40gp_price = m40gp_price*this.form.num_40GP
            m40hq_price = m40hq_price*this.form.num_40HQ
            bj_price = bj_price*this.form.baojia*(this.form.num_20GP+this.form.num_40GP+this.form.num_40HQ)

            return m20gp_price + m40gp_price + m40hq_price + bj_price
        },
        //海运+装港驳船费CNY
        money1_cny() {
            let m20gp_price = 0
            let m40gp_price = 0
            let m40hq_price = 0
            if(this.row_data.routeProductPricingList) {
                this.row_data.routeProductPricingList.forEach(e => {
                    if((e.cntrType==='20GP') && (e.currency==='CNY')) {
                        m20gp_price = e.price
                    } else if((e.cntrType==='40GP') && (e.currency==='CNY')) {
                        m40gp_price = e.price
                    } else if((e.cntrType==='40HQ') && (e.currency==='CNY')) {
                        m40hq_price = e.price
                    }
                })
            }
        
            m20gp_price = m20gp_price*this.form.num_20GP
            m40gp_price = m40gp_price*this.form.num_40GP
            m40hq_price = m40hq_price*this.form.num_40HQ

            return m20gp_price + m40gp_price + m40hq_price
        },
        // 单证费USD
        money2_usd() {
            let arr = []
            let money = 0
            if(this.row_extra_charge['BL']) {
                money = this.row_extra_charge['BL'].reduce((s,cur) => {
                    let m = 0
                    if((cur.type==='POR') || (cur.type==='OCEAN')) {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)
            }
            return money * this.form.num_DZ
        },
        // 单证费CNY
        money2_cny() {
            let arr = []
            let money = 0
            if(this.row_extra_charge['BL']) {
                money = this.row_extra_charge['BL'].reduce((s,cur) => {
                    let m = 0
                    if((cur.type==='POR') || (cur.type==='OCEAN')) {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)
            }
            return money * this.form.num_DZ
        },
        //起运地附加费USD
        money3_usd() {
            let arr = []
            let m20gp_price = 0
            let m40gp_price = 0
            let m40hq_price = 0
            if(this.row_extra_charge['20GP']) {
                m20gp_price = this.row_extra_charge['20GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='POR') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40gp_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='POR') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40hq_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='POR') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)
            }

            return m20gp_price * this.form.num_20GP + m40gp_price * this.form.num_40GP + m40hq_price * this.form.num_40HQ
        },
        //起运地附加费CNY
        money3_cny() {
            let arr = []
            let m20gp_price = 0
            let m40gp_price = 0
            let m40hq_price = 0
            if(this.row_extra_charge['20GP']) {
                m20gp_price = this.row_extra_charge['20GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='POR') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40gp_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='POR') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40hq_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='POR') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)
            }

            return m20gp_price * this.form.num_20GP + m40gp_price * this.form.num_40GP + m40hq_price * this.form.num_40HQ
        },
        //海运附加费USD
        money4_usd() {
            let arr = []
            let m20gp_price = 0
            let m40gp_price = 0
            let m40hq_price = 0
            if(this.row_extra_charge['20GP']) {
                m20gp_price = this.row_extra_charge['20GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='OCEAN') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40gp_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='OCEAN') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40hq_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='OCEAN') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'USD') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)
            }
            
            return m20gp_price * this.form.num_20GP + m40gp_price * this.form.num_40GP + m40hq_price * this.form.num_40HQ
        },
        //海运附加费CNY
        money4_cny() {
            let arr = []
            let m20gp_price = 0
            let m40gp_price = 0
            let m40hq_price = 0
            if(this.row_extra_charge['20GP']) {
                m20gp_price = this.row_extra_charge['20GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='OCEAN') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40gp_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='OCEAN') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)

                m40hq_price = this.row_extra_charge['40GP'].reduce((s,cur) => {
                    let m = 0
                    if(cur.type==='OCEAN') {
                        arr = cur.price.split(" ")
                        if(arr[0] === 'CNY') {
                            m = arr[1]
                        }
                    }
                    return s + m * 1
                },0)
            }
            
            return m20gp_price * this.form.num_20GP + m40gp_price * this.form.num_40GP + m40hq_price * this.form.num_40HQ
        },
        //总费用
        sum_money() {
            let usd = 0
            let cny = 0
            
            usd = this.money1_usd*1 + this.money2_usd*1 + this.money3_usd*1 + this.money4_usd*1
            cny = this.money1_cny*1 + this.money2_cny*1 + this.money3_cny*1 + this.money4_cny*1

            let html = ''
            html += '<div style="color: #2828FF;">USD ' + thousandBitSeparator(fomatFloat(usd)) + '</div>'
            html += '<div style="color: #EA0000;">CNY ' + thousandBitSeparator(fomatFloat(cny)) + '</div>'
            return html
        }
    },
    methods:{
        closeDialog() {
            this.form = {
                num_20GP: 0,
                num_40GP: 0,
                num_40HQ: 0,
                num_DZ: 0,
                baojia: 0
            }
            this.order_data = []
            this.$emit('exitDialog')
        },
        ExitAddressDialog() {
            this.AddressFormVisible = false
        },
        add_order() {
            if(this.form.num_20GP + this.form.num_40GP + this.form.num_40HQ === 0) {
                this.$message.error('下单数量不能为0');return
            }
            if(this.form.num_DZ === 0) {
                this.$message.error('单证数量不能为0');return
            }
            
            this.AddressFormVisible = true
        },
        details_order(order_id) {
            this.closeDialog()
            //跳转到订单列表页面
            this.$router.replace('/CopOrderIndex')
        }
    },
    filters:{
        diy_price(v) {
            const arr = v.split(" ")
            return arr[1] ? arr[0]+' '+thousandBitSeparator(fomatFloat(arr[1])) : v
        }
    },
    props:{
        dialogFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        row_data: {
            type: Object,
            default() {
                return {}
            },
        },
        row_extra_charge: {
            type: Object,
            default() {
                return {}
            },
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        AddressFormComponent
    },
    watch: {},
}
</script>

<style>
.fieldsetc {
    margin-bottom: 20px;
}
.legendc {
    color: #921AFF;
}
.legend_price1 {
    color: #000000;
}
.legend_price2 {
    color: blue;
}
</style>