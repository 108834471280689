<template>
    <div>
        <el-dialog
            title="订舱信息填写"
            :visible.sync="AddressFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="AddressForm"
                    :rules="rules"
                    ref="AddressEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item
                                label="标题"
                                :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
                            >
                                <el-input v-model="cop_title" clearable ></el-input>
                            </el-form-item>
                            <el-form-item label="内容">
                                <el-input v-model="cop_content" type="textarea" :rows="3"></el-input>
                            </el-form-item>
                            <el-form-item label="选择模板">
                                <el-select
                                    v-model="cop_template_id"
                                    filterable
                                    clearable
                                    placeholder="选择模板"
                                    class="templatec"
                                    @change="details_cop_template"
                                >
                                    <el-option
                                        v-for="item in AllTemplate"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">发货人信息</legend>
                            <el-form-item label="名称" prop="shipper_name">
                                <el-input v-model="AddressForm.shipper_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="shipper_phone">
                                        <el-input v-model="AddressForm.shipper_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="shipper_email">
                                        <el-input v-model="AddressForm.shipper_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="国家及地区" prop="shipper_city">
                                        <el-input type="textarea" :rows="3" v-model="AddressForm.shipper_city" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="街道" prop="shipper_street">
                                        <el-input type="textarea" :rows="3" v-model="AddressForm.shipper_street" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="shipper_address">
                                <el-input type="textarea" :rows="3" v-model="AddressForm.shipper_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">收货人信息</legend>
                            <el-form-item label="名称" prop="consignee_name">
                                <el-input v-model="AddressForm.consignee_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="consignee_phone">
                                        <el-input v-model="AddressForm.consignee_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="consignee_email">
                                        <el-input v-model="AddressForm.consignee_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="国家及地区" prop="consignee_city">
                                        <el-input type="textarea" :rows="3" v-model="AddressForm.consignee_city" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="街道" prop="consignee_street">
                                        <el-input type="textarea" :rows="3" v-model="AddressForm.consignee_street" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="consignee_address">
                                <el-input type="textarea" :rows="3" v-model="AddressForm.consignee_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">通知人信息</legend>
                            <el-form-item label="名称" prop="notify_party_name">
                                <el-input v-model="AddressForm.notify_party_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="notify_party_phone">
                                        <el-input v-model="AddressForm.notify_party_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="notify_party_email">
                                        <el-input v-model="AddressForm.notify_party_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="国家及地区" prop="notify_party_city">
                                        <el-input type="textarea" :rows="3" v-model="AddressForm.notify_party_city" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="街道" prop="notify_party_street">
                                        <el-input type="textarea" :rows="3" v-model="AddressForm.notify_party_street" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="notify_party_address">
                                <el-input type="textarea" :rows="3" v-model="AddressForm.notify_party_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">联系人信息</legend>
                            <el-form-item label="名称" prop="emergency_contact_name">
                                <el-input v-model="AddressForm.emergency_contact_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="emergency_contact_phone">
                                        <el-input v-model="AddressForm.emergency_contact_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="emergency_contact_email">
                                        <el-input v-model="AddressForm.emergency_contact_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="emergency_contact_address">
                                <el-input type="textarea" :rows="3" v-model="AddressForm.emergency_contact_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">货物信息</legend>
                            <el-form-item label="货物名称" prop="cargo_name">
                                <el-input v-model="AddressForm.cargo_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item  label="包装标志" prop="cargo_package_type">
                                        <el-select
                                            v-model="AddressForm.cargo_package_type"
                                            filterable
                                            clearable
                                            placeholder="选择包装标志"
                                            class="cargo_package_typec"
                                        >
                                            <el-option
                                                v-for="item in AllPackageType"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.type"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="货物数量" prop="cargo_num">
                                        <el-input v-model="AddressForm.cargo_num" clearable><i slot="suffix">件</i></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="货物重量" prop="cargo_weight">
                                        <el-input v-model="AddressForm.cargo_weight" clearable><i slot="suffix">KG</i></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="货物体积" prop="cargo_volume">
                                        <el-input v-model="AddressForm.cargo_volume" clearable><i slot="suffix">m³</i></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="货物说明" prop="cargo_note">
                                <el-input type="textarea" :rows="3" v-model="AddressForm.cargo_note" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div>
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('AddressEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { cop_template_list_request,cop_package_type_list_request } from '@/network/list'
import { cop_template_details_request } from '@/network/cop/CopTemplate.js'
import { cop_order_add_request } from '@/network/cop/CopOrder.js'

export default {
    name: 'AddressFormComponent',
    data() {
        return {
            login_loading: false,
            cop_template_id: '',
            cop_title: '',
            cop_content: '',
            AddressForm: {
                shipper_name: '',
                shipper_city: '',
                shipper_street: '',
                shipper_address: '',
                shipper_phone: '',
                shipper_email: '',
                consignee_city: '',
                consignee_name: '',
                consignee_street: '',
                consignee_address: '',
                consignee_phone: '',
                consignee_email: '',
                notify_party_name: '',
                notify_party_city: '',
                notify_party_street: '',
                notify_party_address: '',
                notify_party_phone: '',
                notify_party_email: '',
                emergency_contact_name: '',
                emergency_contact_address: '',
                emergency_contact_phone: '',
                emergency_contact_email: '',
                cargo_name: '',
                cargo_package_type: '',
                cargo_num: '',
                cargo_weight: '',
                cargo_volume: '',
                cargo_note: ''
            },
            rules: {
                shipper_name: [
                    {
                        required: true,
                        message: '发货人名称必填',
                        trigger: 'blur',
                    }
                ],
                shipper_city: [
                    {
                        required: true,
                        message: '发货人国家/地区必填',
                        trigger: 'blur',
                    }
                ],
                shipper_street: [
                    {
                        required: true,
                        message: '发货人街道必填',
                        trigger: 'blur',
                    }
                ],
                shipper_phone: [
                    {
                        required: true,
                        message: '发货人电话必填',
                        trigger: 'blur',
                    }
                ],
                consignee_name: [
                    {
                        required: true,
                        message: '收货人名称必填',
                        trigger: 'blur',
                    }
                ],
                consignee_city: [
                    {
                        required: true,
                        message: '收货人国家/地区必填',
                        trigger: 'blur',
                    }
                ],
                consignee_street: [
                    {
                        required: true,
                        message: '收货人街道必填',
                        trigger: 'blur',
                    }
                ],
                consignee_phone: [
                    {
                        required: true,
                        message: '收货人电话必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_name: [
                    {
                        required: true,
                        message: '通知人名称必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_city: [
                    {
                        required: true,
                        message: '通知人国家/地区必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_street: [
                    {
                        required: true,
                        message: '通知人街道必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_phone: [
                    {
                        required: true,
                        message: '通知人电话必填',
                        trigger: 'blur',
                    }
                ],
                emergency_contact_email: [
                    {
                        required: true,
                        message: '订舱人邮箱必填',
                        trigger: 'blur',
                    }
                ],
                cargo_name: [
                    {
                        required: true,
                        message: '货物名称必填',
                        trigger: 'blur',
                    }
                ],
                cargo_package_type: [
                    {
                        required: true,
                        message: '包装标志必填',
                        trigger: 'blur',
                    }
                ],
                cargo_num: [
                    {
                        required: true,
                        message: '货物数量必填',
                        trigger: 'blur',
                    }
                ],
                cargo_weight: [
                    {
                        required: true,
                        message: '货物重量必填',
                        trigger: 'blur',
                    }
                ],
                cargo_volume: [
                    {
                        required: true,
                        message: '货物体积必填',
                        trigger: 'blur',
                    }
                ]
            },
            AllTemplate: [],
            AllPackageType: []
        }
    },
    computed: {},
    methods: {
        closeDialog() {
            this.resetForm()
            this.$emit('ExitAddressDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.AddressFormInsert()
            })
        },
        AddressFormInsert() {
            let order = {}
            order.product_id = this.row_data.productId
            order.title = this.cop_title
            order.content = this.cop_content
            order.blQuantity = this.container.num_DZ
            order.includeInsurance = this.container.baojia

            order.containerInfo = []
            if(this.container.num_20GP > 0) {
                order.containerInfo.push({ containerType: '20GP',quantity: this.container.num_20GP })
            }
            if(this.container.num_40GP > 0) {
                order.containerInfo.push({ containerType: '40GP',quantity: this.container.num_40GP })
            }
            if(this.container.num_40HQ > 0) {
                order.containerInfo.push({ containerType: '40HQ',quantity: this.container.num_40HQ })
            }

            order.shipperInfo = {
                name: this.AddressForm.shipper_name,
                addressLine1: this.AddressForm.shipper_city,
                addressLine2: this.AddressForm.shipper_street,
                addressText: this.AddressForm.shipper_address,
                phone: this.AddressForm.shipper_phone,
                email: this.AddressForm.shipper_email,
            }

            order.consigneeInfo = {
                name: this.AddressForm.consignee_name,
                addressLine1: this.AddressForm.consignee_city,
                addressLine2: this.AddressForm.consignee_street,
                addressText: this.AddressForm.consignee_address,
                phone: this.AddressForm.consignee_phone,
                email: this.AddressForm.consignee_email,
            }

            order.notify_partyInfo = {
                name: this.AddressForm.notify_party_name,
                addressLine1: this.AddressForm.notify_party_city,
                addressLine2: this.AddressForm.notify_party_street,
                addressText: this.AddressForm.notify_party_address,
                phone: this.AddressForm.notify_party_phone,
                email: this.AddressForm.notify_party_email,
            }

            order.emergency_contactInfo = {
                name: this.AddressForm.emergency_contact_name,
                addressText: this.AddressForm.emergency_contact_address,
                phone: this.AddressForm.emergency_contact_phone,
                email: this.AddressForm.emergency_contact_email,
            }

            order.cargoInfo = {
                desc: this.AddressForm.cargo_name,
                packageType: this.AddressForm.cargo_package_type,
                quantity: this.AddressForm.cargo_num,
                weight: this.AddressForm.cargo_weight,
                volume: this.AddressForm.cargo_volume,
                remarks: this.AddressForm.cargo_note
            }
            
            this.login_loading = true
            cop_order_add_request(order)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('内部订单创建成功，需要审批通过之后订单才能生效!')
                        this.$emit('closeDialog')
                        this.$emit('details_order',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.cop_template_id = '',
            this.cop_title = '',
            this.cop_content = '',
            this.AddressForm = {
                shipper_name: '',
                shipper_city: '',
                shipper_street: '',
                shipper_address: '',
                shipper_phone: '',
                shipper_email: '',
                consignee_city: '',
                consignee_name: '',
                consignee_street: '',
                consignee_address: '',
                consignee_phone: '',
                consignee_email: '',
                notify_party_name: '',
                notify_party_city: '',
                notify_party_street: '',
                notify_party_address: '',
                notify_party_phone: '',
                notify_party_email: '',
                emergency_contact_name: '',
                emergency_contact_address: '',
                emergency_contact_phone: '',
                emergency_contact_email: '',
                cargo_name: '',
                cargo_package_type: '',
                cargo_num: '',
                cargo_weight: '',
                cargo_volume: '',
                cargo_note: ''
            }
        },
        get_all_template() {
            cop_template_list_request({})
                .then((s) => {
                    this.AllTemplate = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_package_type() {
            cop_package_type_list_request({})
                .then((s) => {
                    this.AllPackageType = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        details_cop_template(id) {
            this.fid = id
            this.fdialogFormVisible = true
            cop_template_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.AddressForm = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    filters: {},
    props: {
        AddressFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        row_data: {
            type: Object,
            default() {
                return {}
            }
        },
        container: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created() {
        this.get_all_template()
        this.get_all_package_type()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less'>
.templatec,.cargo_package_typec {
    width: 100%;
}
</style>