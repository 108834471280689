import request from '../request'

//港口查询
export const cop_port_search_request = p => {
    return request({
        method:'GET',
        url:'cop/port_search',
        params: {
            port: p
        }
    })
}

//运价查询
export const cop_freight_search_request = p => {
    return request({
        method:'GET',
        url:'cop/freight_search',
        params: p
    })
}

//附加费查询
export const cop_extra_charge_request = p => {
    return request({
        method:'GET',
        url:'cop/extra_charge',
        params: p
    })
}